<template>
  <div>
    <v-card class="mb-7">
      <v-card-title>
        Change Password
      </v-card-title>

      <v-card-text>
        <v-alert class="mb-6" :type="alert.type" dismissible dense outlined v-if="alert.type !== ''">
          {{ alert.msg }}
        </v-alert>
        <v-form class="multi-col-validation" ref="form">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="curPassword"
                outlined
                dense
                label="Current Password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                :rules="[required]"
                @click:append="isCurPasswordVisible = !isCurPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                label="New Password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                :rules="[required]"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                label="Confirm New Password"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                :rules="[required, confirmedValidator(confirmPassword,newPassword)]"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" @click="submitChangePassword" :loading="isProcessing">
                Change Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required, confirmedValidator } from '@core/utils/validation'
import { ref, reactive } from '@vue/composition-api'
import axios from '@axios'

export default {
  setup() {
    const form = ref(null)
    const curPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')
    const isCurPasswordVisible = ref(false)
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const isProcessing = ref(false)
    const alert = reactive({
      type: '',
      msg: '',
    })
    const submitChangePassword = () => {
      alert.type = ''
      if (form.value.validate()) {
        isProcessing.value = true
        axios
          .post('/user/changepass', {
            curr_pass: curPassword.value,
            new_pass: newPassword.value,
          })
          .then(response => {
            const { code, message } = response.data
            if (code !== '0') {
              alert.type = 'error'
              alert.msg = message
            } else {
              alert.type = 'success'
              alert.msg = 'Password changed!'
              form.value.reset()
            }
          })
          .catch(() => {
            alert.type = 'error'
            alert.msg = 'Error while processing'
          })
          .finally(() => { isProcessing.value = false })
      }
    }

    return {
      form,
      curPassword,
      newPassword,
      confirmPassword,
      isProcessing,
      isCurPasswordVisible,
      isPasswordVisible,
      isPasswordConfirmVisible,
      alert,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      submitChangePassword,
      required,
      confirmedValidator,
    }
  },
}
</script>
